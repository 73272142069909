import { useEffect, useState } from "react"
import Button from "../components/button";
import TextField from "./fields/text-field";
import { api } from '../api.service.js'
import Loading from "../components/loading";

export default function FormWrapper({ url, id, callback, children, includeIdInPost, buttonText, hideButton, noStyle, defaultValue, noId, apiSuffix }) {
    const [values, setValues] = useState();

    useEffect(() => {
        if (noId) {
            api(`${process.env.REACT_APP_API_URL}/${url}${apiSuffix ?? ''}`).then(x => {
                console.log(x)
                setValues(x ?? {});
            });
        }
        else {
            if (id !== "new")
                api(`${process.env.REACT_APP_API_URL}/${url}/${id}${apiSuffix ?? ''}`).then(x => {
                    setValues(x ?? {});
                });
            else {
                setValues(defaultValue ?? {})
            }
        }

    }, [apiSuffix])

    const submit = (data) => {
        if (includeIdInPost && !noId) {
            api(`${process.env.REACT_APP_API_URL}/${url}/${id}${apiSuffix ?? ''}`, { data: data ?? values }).then(x => {
                callback();
            });
        }
        else {
            api(`${process.env.REACT_APP_API_URL}/${url}${apiSuffix ?? ''}`, { data: data ?? values }).then(x => {
                callback();
            });
        }
    }

    if (!values) return <Loading></Loading>

    return <div className={noStyle ? '' : 'max-w-6xl mx-auto my-10 bg-white shadow-lg rounded-lg p-5'}>
        {children(values, setValues, submit)}

        {!hideButton && <Button className="my-10" text={buttonText ?? "Submit"} onClick={() => submit()}></Button>}
    </div>
}