import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { api } from "../../api.service";
import SelectField from "./select-field";

export default function EquationBuilder({ label, value, onChange, className, inputClass, labelClass, inline }) {

    const [metrics, setMetrics] = useState()
    const [staticValues, setStaticValues] = useState()

    useEffect(() => {

        api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => setMetrics(x.map(x => { return { text: x.name, value: x._id } })))
        api(`${process.env.REACT_APP_API_URL}/admin/static-value`).then(x => setStaticValues(x.map(x => { return { text: x.name, value: x._id } })))

    }, [])
    const legalChars = '1234567890+-*/().';
    const onKeyDown = (e) => {
        if (legalChars.includes(e.key)) {
            onChange((value ?? '') + e.key)
        }

        if (e.key == "Backspace") {
            if (value.substring(value.length - 1) == "}") {
                let valParts = value?.split(/[{}]+/).filter(x => x.trim() != '');
                valParts.pop();
                let newVal = "";
                for (let v of valParts) {
                    if (v.startsWith("metric_")) newVal += `{${v}}`;
                    else if (v.startsWith("sv_")) newVal += `{${v}}`;
                    else newVal += v;
                }
                onChange(newVal)
            }
            else {
                onChange(value ? value.substring(0, value.length - 1) : '')
            }
        }

    }

    const parts = value?.split(/[{}]+/);

    return <div className={className + 'border'}>
        <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>
            {label}
        </label>
        <div className="bg-neutral-50 border p-3 flex select-none">
            <div>
                {metrics && <SelectField className="w-64" options={metrics} placeholder="Metric..." onChange={(val) => onChange((value ?? '') + `{metric_${val}}`)}></SelectField>}
                {staticValues && <SelectField className="w-64" options={staticValues} placeholder="Conversion Factor..." onChange={(val) => onChange((value ?? '') + `{sv_${val}}`)}></SelectField>}
            </div>
            <div className="ml-5">
                <div className="flex space-x-1 mt-2">
                    {['+', '-', '*', '/', '(', ')'].map(x => <div onClick={() => onChange((value ?? '') + x + '')} className="hover:bg-neutral-400 w-10 h-10 cursor-pointer transition-all bg-neutral-300 text-xl text-center font-bold text-neutral-800 p-1 shadow rounded">{x}</div>)}
                </div>
                <div className="flex space-x-1 mt-1">
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => <div onClick={() => onChange((value ?? '') + x + '')} className="hover:bg-neutral-400 w-10 h-10 cursor-pointer transition-all bg-neutral-300 text-xl text-center font-bold text-neutral-800 p-1 shadow rounded">{x}</div>)}
                </div>
            </div>

            <div className="flex-1"></div>
            <div onClick={() => onKeyDown({ key: 'Backspace' })} className="hover:bg-neutral-400 mt-2 cursor-pointer w-12 pl-2 h-10 transition-all bg-neutral-300 text-xl text-center font-bold text-neutral-800 p-1 shadow rounded mx-1"><ArrowLeftIcon className="text-neutral-800 w-8"></ArrowLeftIcon></div>

        </div>

        <div tabIndex="0" className="border focus:border-blue-500 p-2 font-semibold text-2xl " onKeyDown={onKeyDown}>
            {parts?.map(x => {
                if (x.startsWith("metric_")) {
                    return <span className="border border-dashed text-blue-500 text-lg mx-2">{metrics?.find(y => y.value == x.substring(7))?.text}</span>
                }
                if (x.startsWith("sv_")) {
                    return <span className="border border-dashed text-amber-500 text-lg mx-2">{staticValues?.find(y => y.value == x.substring(3))?.text}</span>
                }
                else {
                    return x
                }

            })}
        </div>
    </div>
}