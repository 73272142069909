import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import NumberField from './forms/fields/number-field'
import TextField from './forms/fields/text-field'
import DateField from './forms/fields/date-field'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import DateTimeField from './forms/fields/datetime-field'
import PercentageField from './forms/fields/percentage-field'
import CurrencyField from './forms/fields/currency-field'
import CheckboxField from './forms/fields/checkbox-field'
import FileField from './forms/fields/file-field'
import SelectField from './forms/fields/select-field'
import ListBoxField from './forms/fields/listbox-field'

export default function PcMetrics({ isAdmin, pcId }) {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [values, setValues] = useState([])
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isAdmin) api(`${process.env.REACT_APP_API_URL}/admin/pc-metric/${pcId}`).then(x => setData(x))
    else api(`${process.env.REACT_APP_API_URL}/pc/metric`).then(x => setData(x))
  }, [pcId])
  useEffect(() => {
    if (isAdmin) api(`${process.env.REACT_APP_API_URL}/admin/pc-metric-value/${pcId}`).then(x => setValues(x))
    else api(`${process.env.REACT_APP_API_URL}/pc/metric-value`).then(x => setValues(x))
  }, [pcId])

  const submit = (metric) => {
    setSaving(true)
    if (isAdmin) api(`${process.env.REACT_APP_API_URL}/admin/pc-metric-value/${pcId}`, { data: metric }).then(x => api(`${process.env.REACT_APP_API_URL}/admin/pc-metric-value/${pcId}`).then(x => {setValues(x);setSaving(false)}))
    else api(`${process.env.REACT_APP_API_URL}/pc/metric-value`, { data: metric }).then(x => api(`${process.env.REACT_APP_API_URL}/pc/metric-value`).then(x => {setValues(x); setSaving(false)}))
  }
  // const Download = () => {

  //   const options = {
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalSeparator: '.',
  //     showLabels: true,
  //     useTextFile: false,
  //     useBom: true,
  //     headers: ['PC', ...values.metrics.map(x => metrics.find(y => y._id == x)?.name)]
  //   };
  //   const csvExporter = new ExportToCsv(options);
  //   csvExporter.generateCsv(loadStaticChartData(metrics.filter(x => values.metrics.includes(x._id)), metricValues, metrics, metricValues, false));
  // }

  if (!data) return <Loading></Loading>
  return (<>
    {!isAdmin && <PageHeader headline={"Metrics"}></PageHeader>}
    {!saving && <div className="fixed bottom-5 right-5 bg-green-500 text-white text-xs p-1 px-3 font-medium rounded shadow">Saved</div>}
    {saving && <div className="fixed bottom-5 right-5 bg-blue-500 text-white text-xs p-1 px-3 font-medium rounded shadow">Saving...</div>}
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">

        <div className="flex flex-col bg-white p-10 rounded-lg shadow-lg my-5">
          <div className="text-center text-2xl font-neutral-800 font-semibold mb-5">General Data Points</div>
          <MetricGrid category="0" values={values} data={data.sort((a, b) => a.name < b.name ? -1 : 1).sort((a, b) => a.category < b.category ? -1 : 1)} setValues={setValues} setData={setData} submit={submit}></MetricGrid>

        </div>
        <div className="flex flex-col bg-white p-10 rounded-lg shadow-lg my-5">
          <div className="text-center text-2xl font-neutral-800 font-semibold mb-5">Metrics</div>

          <MetricGrid category="1" values={values} data={data.sort((a, b) => a.name < b.name ? -1 : 1).sort((a, b) => a.category < b.category ? -1 : 1)} setValues={setValues} setData={setData} submit={submit}></MetricGrid>
        </div>

        <div className="flex flex-col bg-white p-10 rounded-lg shadow-lg my-5">
          <div className="text-center text-2xl font-neutral-800 font-semibold mb-5">ESG</div>

          <MetricGrid category="2" values={values} data={data.sort((a, b) => a.name < b.name ? -1 : 1).sort((a, b) => a.category < b.category ? -1 : 1)} setValues={setValues} setData={setData} submit={submit}></MetricGrid>

        </div>

      </div>
    </div>
  </>
  )
}

const MetricGrid = ({ category, values, setValues, submit, data, setData }) => {


  return <div className="border-b border-neutral-200">
    {data.map((x, i) => <>

      {x.systemCategory == category && !x.computational && <div className="border-t border-x border-neutral-200">
        <div className="flex">
          <div className="w-64 shrink-0 border-r border-neutral-200 flex flex-col">
            <div className="truncate p-2 text-center my-auto font-semibold text-neutral-800">
              {x.name}
              <div className="text-sm text-neutral-500">{x.category}</div>
            </div>
          </div>
          <div className="flex flex-1 place-items-center p-2">

            {x.interval == 5 && <>
              <div className="overflow-hidden flex-1" key={x._id}>
                {x.type == 0 && <TextField className="flex-1 text-center" onBlur={() => submit(values.find(y => y.metric == x._id))} onChange={(val) => setValues(values.find(y => y.metric == x._id) ? values.map((y, j) => (y.metric == x._id) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, value: val }]))} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 1 && <TextField className="flex-1 text-center" onBlur={() => submit(values.find(y => y.metric == x._id))} onChange={(val) => setValues(values.find(y => y.metric == x._id) ? values.map((y, j) => (y.metric == x._id) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, value: val }]))} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 2 && <NumberField className="flex-1 text-center" onBlur={() => submit(values.find(y => y.metric == x._id))} onChange={(val) => setValues(values.find(y => y.metric == x._id) ? values.map((y, j) => (y.metric == x._id) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, value: val }]))} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 3 && <CurrencyField className="flex-1 text-center" onBlur={() => submit(values.find(y => y.metric == x._id))} onChange={(val) => setValues(values.find(y => y.metric == x._id) ? values.map((y, j) => (y.metric == x._id) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, value: val }]))} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 4 && <PercentageField className="flex-1 text-center" onBlur={() => submit(values.find(y => y.metric == x._id))} onChange={(val) => setValues(values.find(y => y.metric == x._id) ? values.map((y, j) => (y.metric == x._id) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, value: val }]))} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 5 && <DateField className="flex-1 text-center" onBlur={() => submit(values.find(y => y.metric == x._id))} onChange={(val) => setValues(values.find(y => y.metric == x._id) ? values.map((y, j) => (y.metric == x._id) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, value: val }]))} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 6 && <SelectField options={x.options} className="flex-1 text-center" onChange={(val) => submit({ ...(values.find(y => y.metric == x._id) ?? { metric: x._id }), value: val })} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 7 && <ListBoxField options={x.options} className="flex-1 text-center" onChange={(val) => submit({ ...(values.find(y => y.metric == x._id) ?? { metric: x._id }), value: val })} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 8 && <CheckboxField className="flex-1 text-center" onChange={(val) => submit({ ...(values.find(y => y.metric == x._id) ?? { metric: x._id }), value: val })} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
                {x.type == 9 && <FileField className="flex-1 text-center" endpoint="document" onChange={(val) => { setValues(values.find(y => y.metric == x._id) ? values.map((y, j) => (y.metric == x._id) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, value: val }])); submit({ metric: x._id, value: val }) }} value={values.find(y => y.metric == x._id)?.value} label={"Value"} />}
              </div>
            </>}

            {x.interval != 5 && <>
              <div className="select-none bg-neutral-100 shadow-md rounded mr-2 cursor-pointer  hover:bg-neutral-200 transition-all" onClick={() => setData(data.map((y, j) => (j === i && y.systemCategory == category) ? { ...y, offset: y.offset ? y.offset - 1 : -1 } : y))}><ChevronLeftIcon className="w-12 text-neutral-700 hover:text-neutral-800 cursor-pointer"></ChevronLeftIcon></div>
              <div className="grid grid-cols-4 gap-2 flex-1">


                {get4Ranges(x)?.map(date => {
                  return <div key={x._id + getDateString(date, x)}>
                    {x.type == 0 && <TextField className="text-center" onBlur={() => submit(values.find(y => y.metric == x._id && y.date == getDateString(date, x)))} onChange={(val) => setValues(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ? values.map((y, j) => (y.metric == x._id && y.date == getDateString(date, x)) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, date: getDateString(date, x), value: val }]))} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 1 && <TextField className="text-center" onBlur={() => submit(values.find(y => y.metric == x._id && y.date == getDateString(date, x)))} onChange={(val) => setValues(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ? values.map((y, j) => (y.metric == x._id && y.date == getDateString(date, x)) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, date: getDateString(date, x), value: val }]))} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 2 && <NumberField className="text-center" onBlur={() => submit(values.find(y => y.metric == x._id && y.date == getDateString(date, x)))} onChange={(val) => setValues(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ? values.map((y, j) => (y.metric == x._id && y.date == getDateString(date, x)) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, date: getDateString(date, x), value: val }]))} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 3 && <CurrencyField className="text-center" onBlur={() => submit(values.find(y => y.metric == x._id && y.date == getDateString(date, x)))} onChange={(val) => setValues(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ? values.map((y, j) => (y.metric == x._id && y.date == getDateString(date, x)) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, date: getDateString(date, x), value: val }]))} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 4 && <PercentageField className="text-center" onBlur={() => submit(values.find(y => y.metric == x._id && y.date == getDateString(date, x)))} onChange={(val) => setValues(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ? values.map((y, j) => (y.metric == x._id && y.date == getDateString(date, x)) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, date: getDateString(date, x), value: val }]))} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 5 && <DateField className="text-center" onBlur={() => submit(values.find(y => y.metric == x._id && y.date == getDateString(date, x)))} onChange={(val) => setValues(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ? values.map((y, j) => (y.metric == x._id && y.date == getDateString(date, x)) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, date: getDateString(date, x), value: val }]))} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 6 && <SelectField options={x.options} className="text-center relative z-40" onChange={(val) => submit({ ...(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ?? { metric: x._id, date: getDateString(date, x) }), value: val })} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 7 && <ListBoxField options={x.options} className="text-center" onChange={(val) => submit({ ...(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ?? { metric: x._id, date: getDateString(date, x) }), value: val })} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)} />}
                    {x.type == 8 && <CheckboxField className="text-center" onChange={(val) => submit({ ...(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ?? { metric: x._id, date: getDateString(date, x) }), value: val })} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)}></CheckboxField>}
                    {x.type == 9 && <FileField className="text-center" endpoint="document" onChange={(val) => { setValues(values.find(y => y.metric == x._id && y.date == getDateString(date, x)) ? values.map((y, j) => (y.metric == x._id && y.date == getDateString(date, x)) ? { ...y, value: val } : y) : values.concat([{ metric: x._id, date: getDateString(date, x), value: val }])); submit({ metric: x._id, date: getDateString(date, x), value: val }) }} value={values.find(y => y.metric == x._id && y.date == getDateString(date, x))?.value} label={getDateString(date, x)}></FileField>}
                  </div>
                })}


              </div>
              <div className="select-none bg-neutral-100 shadow-md rounded ml-2 cursor-pointer  hover:bg-neutral-200 transition-all" onClick={() => setData(data.map((y, j) => (j === i && y.systemCategory == category) ? { ...y, offset: y.offset ? y.offset + 1 : 1 } : y))}><ChevronRightIcon className="w-12 text-neutral-700 hover:text-neutral-800 cursor-pointer"></ChevronRightIcon></div>
            </>}
          </div>
        </div>
      </div>}

    </>


    )}
  </div>
}

const get4Ranges = (metric) => {
  if (!metric.offset) metric.offset = 0
  let dates = [];
  let timeRange = '';
  if (metric.interval == 0) timeRange = 'week';
  if (metric.interval == 1) timeRange = 'month';
  if (metric.interval == 2) timeRange = 'quarter';
  if (metric.interval == 3) timeRange = 'biannual';
  if (metric.interval == 4) timeRange = 'year';
  if (timeRange == 'biannual') {
    dates.push(moment().add((metric.offset) * 2, 'quarter').startOf('quarter'))
    dates.push(moment().add((metric.offset - 1) * 2, 'quarter').startOf('quarter'))
    dates.push(moment().add((metric.offset - 2) * 2, 'quarter').startOf('quarter'))
    dates.push(moment().add((metric.offset - 3) * 2, 'quarter').startOf('quarter'))
  }
  else {
    dates.push(moment().add(metric.offset, timeRange).startOf(timeRange))
    dates.push(moment().add(metric.offset - 1, timeRange).startOf(timeRange))
    dates.push(moment().add(metric.offset - 2, timeRange).startOf(timeRange))
    dates.push(moment().add(metric.offset - 3, timeRange).startOf(timeRange))
  }

  return dates.slice().reverse();
}

const getDateString = (date, metric) => {
  if (metric.interval == 0) return date.format('[WC] DD/MM/YYYY')
  if (metric.interval == 1) return date.format('MMM YYYY')
  if (metric.interval == 2) return date.format('[Q]Q YYYY')
  if (metric.interval == 3) return date.format('[Q]Q YYYY')
  if (metric.interval == 4) return date.format('YYYY')
  return date.format('YYYY')
}