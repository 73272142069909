import { Card, Metric, Text, LineChart, Title, AreaChart, DonutChart } from "@tremor/react";
import { format_currency } from './formatter'
import { DateStringToDate } from './date.service';
import moment from "moment";
import { CalculateScorecard } from "./computations";
import HelpPopup from "./components/help-popup";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ScorecardSummary({ metrics, _metricValues, scorecard, staticValues }) {

  let metricValues = _metricValues.slice().sort((a, b) => DateStringToDate(a.date, metrics.find(x => x._id == a.metric)?.interval) < DateStringToDate(b.date, metrics.find(x => x._id == b.metric)?.interval) ? 1 : -1);

  let scorecardValues = CalculateScorecard(scorecard, metricValues, new Date(), metrics, staticValues)

  return (<>
    <div className="flex flex-col ">
      <div className="text-xl rounded-t-lg p-2 text-center font-semibold text-neutral-800">{scorecard.name}</div>
      {/* <div className="p-5 text-center text-xl font-semibold text-neutral-700">{}</div> */}
      <div className="w-full px-10 max-w-sm mx-auto max-h-44 overflow-hidden">
        <CircularProgressbar circleRatio={0.5} value={scorecardValues.overall} text={`${scorecardValues.overall}%`}
          styles={buildStyles({
            rotation: 0.75,
            pathColor: scorecard.colour,
            textColor: '#000',
          })}
        />
      </div>
    </div>
  </>
  )
}

