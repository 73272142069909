import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api } from './api.service'
import { useNavigate, useParams } from 'react-router-dom'
import Button from './components/button'
import moment from 'moment'
import { formatAnswer } from './formatter'

export default function AdminApproval() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [metrics, setMetrics] = useState(null)
  const [form, setForm] = useState(null)
  const params = useParams();

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/approval/${params.id}`).then(x => { setData(x.response); setMetrics(x.metrics); setForm(x.response.form ?? {}); }) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader subtitle={"Approve or reject form data"} headline={"Approval"}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">

        <div className="text-neutral-600 text-sm font-medium">{form.name}</div>
        <div className="text-neutral-700 text-2xl font-semibold">{data.portfolioCompany?.name}</div>
        <div className="text-neutral-500 text-md font-medium">{moment(data.submittedDate).format('YYYY/MM/DD')}</div>

        {form.sections?.map(section => <div>
          <div className="font-bold text-neutral-800 my-3">{section.title}</div>
          {section?.fields?.map(field => <div className="my-3 bg-white rounded-lg shadow p-3">
            <div className="text-neutral-600 font-semibold text-sm">{field.label}</div>

           {data.values &&  <div className="text-neutral-800 font-medium text-lg mt-2 mb-1 min-h-5">{formatAnswer(data.values[field.id], field.metric ? metrics.find(x => x._id == field.metric)?.type : field.type)}</div>}

            {data.supportingInformation && data.supportingInformation[field.id] && <>
              <div className="text-xs text-neutral-500 font-semibold">Notes</div>
              <div className="text-neutral-600">{data.supportingInformation[field.id]?.notes}</div>
            </>}



          </div>)}

        </div>)}

        <Button className="bg-green-500 hover:bg-green-600 mx-2" onClick={() => api(`${process.env.REACT_APP_API_URL}/admin/approve-form/${params.id}`).then(() => navigate(-1))} text="Approve"></Button>
        <Button className="bg-neutral-500 hover:bg-neutral-600 mx-2" onClick={() => api(`${process.env.REACT_APP_API_URL}/admin/return-form/${params.id}`).then(() => navigate(-1))} text="Return"></Button>
        <Button className="bg-red-500 hover:bg-red-600 mx-2" onClick={() => api(`${process.env.REACT_APP_API_URL}/admin/reject-form/${params.id}`).then(() => navigate(-1))} text="Reject"></Button>

      </div>
    </div>
  </>
  )
}