
import { useNavigate } from 'react-router-dom'
import PageHeader from './components/page-header'
import { useEffect, useState } from 'react'
import { api } from './api.service'
import PcCard from './components/pc-card'
import Loading from './components/loading'
import { Card } from "@tremor/react";
import { LineChart, Title } from "@tremor/react";

export default function InvestorDashboard() {

  const [data, setData] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/investor`).then(x => { setData(x); }); }, [])

  const navigate = useNavigate();
  if (!data) return <Loading></Loading>

  const getChartData = () => {
    let investedData = [];
    let evData = [];
    let lastInvestedValue = 0;
    let allEvs = []
    for (let i of data.investments.sort((a, b) => new Date(a.date) < new Date(b.date) ? -1 : 1)) {
      investedData.push({ date: new Date(i.date), value: i.invested + lastInvestedValue });
      lastInvestedValue = i.invested + lastInvestedValue;

      if (i.evs)
        for (let e of i.evs) {
          allEvs.push({ date: new Date(e.date), value: e.valuation })
        }

    }
    let lastEvValue = 0;

    for (let ev of allEvs.sort((a, b) => new Date(a.date) < new Date(b.date) ? -1 : 1)) {
      evData.push({ date: ev.date, value: (ev.value * 0.005) + lastEvValue })
      lastEvValue = (ev.value * 0.005) + lastEvValue
    }
    return { investments: investedData.sort((a, b) => a.date < b.date ? -1 : 1), evs: evData.sort((a, b) => a.date < b.date ? -1 : 1) }
  }

  return (<>
    <div className="hidden bg-amber-100 text-amber-500 bg-red-100 text-red-500 bg-red-500/30"></div>
    <PageHeader image={data.investor.picture} headline={"Hello, " + data.investor.name}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">
        <div className="pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Portfolio Companies</h3>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-5">
          {data.investments.map(x => <PcCard ev={x.ev} investment={x} pc={x.portfolioCompany} image={x.portfolioCompany.image} onClick={() => navigate("/1/pc/" + x.portfolioCompany._id)}></PcCard>)}
        </div>

        <div className="pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Performance</h3>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Card>
            <Title>Invested</Title>
            <LineChart
              data={getChartData().investments.map(x => { return { date: x.date.toDateString(), "Invested": +x.value } })}
              index="date"
              categories={["Invested"]}
            />
          </Card>
          <Card>
            <Title>Portfolio Value</Title>
            <LineChart
              className="mt-6"
              data={getChartData().evs.map(x => { return { date: x.date.toDateString(), "Portfolio Value": +x.value } })}
              index="date"
              categories={["Portfolio Value"]}
              colors={["blue"]}
            />
          </Card>
        </div>
      </div>
    </div>
  </>
  )
}