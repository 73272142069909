import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import NumberField from "./fields/number-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import Button from "../components/button";
import CheckboxField from "./fields/checkbox-field";
import SelectField from "./fields/select-field";
import { ClockIcon, LightBulbIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { api } from "../api.service";
import { ArrowLeftIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import Loading from "../components/loading";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ListBoxField from "./fields/listbox-field";
import CurrencyField from "./fields/currency-field";
import PercentageField from "./fields/percentage-field";
import FileField from "./fields/file-field";
import { DateStringToDate } from "../date.service";
import moment from "moment";

const getStartDate = (date, interval) => {
    let timeRange = '';
    if (interval == 0) timeRange = 'week';
    if (interval == 1) timeRange = 'month';
    if (interval == 2) timeRange = 'quarter';
    if (interval == 3) timeRange = 'biannual';
    if (interval == 4) timeRange = 'year';
    if (timeRange == 'biannual') {
        if (moment(date).quarter() == 2 || moment(date).quarter() == 2) {
            return moment(date).add(-1, 'quarter').startOf('quarter')
        }
        else {
            return moment(date).startOf('quarter')
        }
    }
    return moment(date).startOf(timeRange)
}

const getCurrentMetricValue = (metric, metricValues) => {
    if (metric.interval == 5) return metricValues[0].value;

    let timeRange = '';
    if (metric.interval == 0) timeRange = 'week';
    if (metric.interval == 1) timeRange = 'month';
    if (metric.interval == 2) timeRange = 'quarter';
    if (metric.interval == 3) timeRange = 'biannual';
    if (metric.interval == 4) timeRange = 'year';
    if (timeRange == 'biannual') {
        if (moment().quarter() == 2 || moment().quarter() == 2) {
            return moment().add(-1, 'quarter').startOf('quarter')
        }
        else {
            return moment().startOf('quarter')
        }
    }
    return moment().startOf(timeRange)


}

export default function FormResponseForm(props) {
    const navigate = useNavigate();
    const params = useParams();
    let id = params.id ?? props.id;
    const [data, setData] = useState();
    const [_metricValues, setMetricValues] = useState();
    const [allMetrics, setAllMetrics] = useState();
    const [currentSection, setCurrentSection] = useState(0);
    const [date, setDate] = useState();
    const [noteTabIds, setNoteTabIds] = useState([])

    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/form/${id}`).then(x => { setData(x); setDate(getStartDate(new Date(params.date == "current" ? new Date() : params.date), x.interval).toDate()) }) }, [params.date])
    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/all-metrics`).then(x => { setAllMetrics(x) }) }, [])
    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/metric-value`).then(x => { setMetricValues(x) }) }, [])

    if (!data || !allMetrics || !_metricValues) return <Loading></Loading>

    let metricValues = _metricValues.sort((a, b) => DateStringToDate(a.date, allMetrics.find(x => x._id == a.metric)?.interval) > DateStringToDate(b.date, allMetrics.find(x => x._id == b.metric)?.interval) ? -1 : 1)

    const rawContentState = data.sections[currentSection]?.description ? data.sections[currentSection]?.description.entityMap ? data.sections[currentSection]?.description : { entityMap: {}, ...data.sections[currentSection]?.description } : { blocks: [], entityMap: {} }
    const markup = draftToHtml(rawContentState);

    const displayField = (field, values) => {
        if (!(field.depRules?.length > 0)) return true;

        let display = true;
        for (let r of field.depRules) {
            let parentFieldValue = values.values ? values.values[r.parent] : null;
            if (parentFieldValue != r.value) display = false;
        }
        return display;
    }

    const formatInterval = (interval, offset) => {
        if (interval === 0) return 'Week commencing' + moment(date).add(offset, 'weeks').format('YYYY/MM/DD')
        if (interval === 1) return moment(date).add(offset, 'months').format('MMM YYYY')
        if (interval === 2) return "Quarter " + moment(date).add(offset, 'quarter').format('Q YYYY')
        if (interval === 3) return "Q" + moment(date).add(offset * 2, 'quarter').format('Q YYYY') + " - " + "Q" + moment(date).add(1, 'quarter').add(offset * 2, 'quarter').format('Q YYYY')
        if (interval === 4) return moment(date).add(offset, 'year').format('YYYY')
        if (interval === 5) return ''
    }
    const getDateUpdatedToOffset = (interval, offset) => {
        if (interval === 0) return moment(date).add(offset, 'weeks').format('YYYY-MM-DD')
        if (interval === 1) return moment(date).add(offset, 'months').format('YYYY-MM-DD')
        if (interval === 2) return moment(date).add(offset, 'quarter').format('YYYY-MM-DD')
        if (interval === 3) return moment(date).add(offset * 2, 'quarter').format('YYYY-MM-DD')
        if (interval === 4) return moment(date).add(offset, 'year').format('YYYY-MM-DD')
    }

    return <div>
        <FormWrapper apiSuffix={'/' + moment(date).format('YYYY-MM-DD')} noStyle={true} hideButton={true} url="pc/form-response" includeIdInPost={true} id={id} {...props} callback={() => navigate('/0')}>
            {(values, setValues, submit) =>
                <div className="h-screen flex flex-col">
                    <div className="text-left p-5 bg-white shadow flex justify-between">
                        <div>
                            <div className="text-2xl font-semibold text-neutral-700">{data.name} - {formatInterval(data.interval)}</div>
                            <div className="text-lg text-neutral-600 flex justify-start space-x-3 mt-2">
                                <div onClick={() => navigate(`/0/form/${params.id}/${getDateUpdatedToOffset(data.interval, -2)}`)} className="cursor-pointer text-neutral-400 text-sm">{formatInterval(data.interval, -2)}</div>
                                <div onClick={() => navigate(`/0/form/${params.id}/${getDateUpdatedToOffset(data.interval, -1)}`)} className="cursor-pointer text-neutral-400 text-sm">{formatInterval(data.interval, -1)}</div>
                                <div className="font-bold text-blue-500 text-lg">{formatInterval(data.interval)}</div>
                                <div onClick={() => navigate(`/0/form/${params.id}/${getDateUpdatedToOffset(data.interval, 1)}`)} className="cursor-pointer text-neutral-400 text-sm">{formatInterval(data.interval, 1)}</div>
                                <div onClick={() => navigate(`/0/form/${params.id}/${getDateUpdatedToOffset(data.interval, 2)}`)} className="cursor-pointer text-neutral-400 text-sm">{formatInterval(data.interval, 2)}</div>
                            </div>
                        </div>
                        {values.approvalStatus != 'approved' && <div className="flex"><Button onClick={() => submit()} text="Save & Continue Later" className="bg-neutral-500 mr-5"></Button><Button text="Submit" onClick={() => { submit({ ...values, status: 'submitted' }) }}></Button></div>}
                    </div>
                    {values.approvalStatus == 'approved' && <div className="p-10 text-center flex-1">
                        <div className="text-2xl font-semibold my-2 text-neutral-800">Data Approved</div>
                        <div className="text-lg font-medium my-2 text-neutral-600">The data for this time period has been approved. <br />Please contact your system administrator to unlock the form if updates are required.</div>
                    </div>}
                    {values.approvalStatus != 'approved' && <div className="bg-neutral-50 shadow-inner flex overflow-y-auto flex-1">

                        {data.sections.length > 1 && <div className="w-96 bg-neutral-100 border-r p-5 overflow-auto">

                            <nav aria-label="Progress">
                                <ol role="list" className="overflow-hidden">
                                    {data.sections.map((section, sectionIndex) => (
                                        <li onClick={() => setCurrentSection(sectionIndex)} key={section.id} className={(sectionIndex !== data.sections.length - 1 ? 'pb-10' : '') + ' select-none cursor-pointer relative'}>
                                            {sectionIndex < currentSection ? (
                                                <>
                                                    {sectionIndex !== data.sections.length - 1 ? (
                                                        <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-600" aria-hidden="true" />
                                                    ) : null}
                                                    <a href={section.href} className="group relative flex items-start">
                                                        <span className="flex h-9 items-center">
                                                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 group-hover:bg-blue-800">
                                                                <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                            </span>
                                                        </span>
                                                        <span className="ml-4 flex min-w-0 flex-col">
                                                            <span className="text-sm font-medium">{section.title}</span>
                                                            <span className="text-sm text-gray-500">{section.details}</span>
                                                        </span>
                                                    </a>
                                                </>
                                            ) : sectionIndex === currentSection ? (
                                                <>
                                                    {sectionIndex !== data.sections.length - 1 ? (
                                                        <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                                    ) : null}
                                                    <a href={section.href} className="group relative flex items-start" aria-current="section">
                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white">
                                                                <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                                                            </span>
                                                        </span>
                                                        <span className="ml-4 flex min-w-0 flex-col">
                                                            <span className="text-sm font-medium text-blue-600">{section.title}</span>
                                                            <span className="text-sm text-gray-500">{section.details}</span>
                                                        </span>
                                                    </a>
                                                </>
                                            ) : (
                                                <>
                                                    {sectionIndex !== data.sections.length - 1 ? (
                                                        <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                                    ) : null}
                                                    <a href={section.href} className="group relative flex items-start">
                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                                                <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                                            </span>
                                                        </span>
                                                        <span className="ml-4 flex min-w-0 flex-col">
                                                            <span className="text-sm font-medium text-gray-500">{section.title}</span>
                                                            <span className="text-sm text-gray-500">{section.details}</span>
                                                        </span>
                                                    </a>
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </nav>
                        </div>}
                        <div className="flex-1 flex flex-col ">
                            <div className="flex-1 flex flex-col overflow-auto ">
                                <div className=" max-w-3xl w-full mx-auto pt-10 pb-8 pl-3">
                                    <div dangerouslySetInnerHTML={{ __html: markup }}></div>
                                    {data.sections[currentSection]?.fields?.filter(x => displayField(x, values)).map(x => <div className="bg-white my-5 shadow rounded-md p-5 relative" key={x.id}>

                                        <div className="absolute right-0">
                                            {!(metricValues.find(y => y.metric == x.metric) && (!values.values || !values.values[x.id])) && metricValues.find(y => DateStringToDate(y.date, allMetrics.find(x => x._id == y.metric)?.interval) < date && y.metric == x.metric) && (!values.values || !values.values[x.id]) &&
                                                <div onClick={() => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: metricValues.find(y => y.metric == x.metric).value } : { [x.id]: metricValues.find(y => y.metric == x.metric).value } })} className="space-x-2 shadow hover:bg-blue-50 hover:scale-105 transform duration-200 flex w-32 absolute left-2 bg-white text-sm font-medium text-neutral-600 cursor-pointer rounded-lg p-1">

                                                    <div className="flex-1 text-center">
                                                        <div className="text-xs text-neutral-500 font-semibold">{metricValues.find(y => DateStringToDate(y.date, allMetrics.find(x => x._id == y.metric)?.interval) < date && y.metric == x.metric).date}</div>
                                                        <div className="text-sm text-neutral-700">{metricValues.find(y => DateStringToDate(y.date, allMetrics.find(x => x._id == y.metric)?.interval) < date && y.metric == x.metric).value}</div>
                                                    </div>
                                                    <div className="shrink-0 flex">
                                                        <CheckIcon className="m-auto w-6 text-green-500" />
                                                    </div>
                                                </div>}
                                            {metricValues.find(y => y.metric == x.metric) && (!values.values || !values.values[x.id]) &&
                                                <div onClick={() => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: metricValues.find(y => y.metric == x.metric).value } : { [x.id]: metricValues.find(y => y.metric == x.metric).value } })} className="space-x-2 shadow hover:bg-blue-50 hover:scale-105 transform duration-200 flex w-32 absolute left-2 bg-white text-sm font-medium text-neutral-600 cursor-pointer rounded-lg p-1">

                                                    <div className="flex-1 text-center">
                                                        <div className="text-xs text-neutral-500 font-semibold">{metricValues.find(y => y.metric == x.metric).date}</div>
                                                        <div className="text-sm text-neutral-700">{metricValues.find(y => y.metric == x.metric).value}</div>
                                                    </div>
                                                    <div className="shrink-0 flex">
                                                        <CheckIcon className="m-auto w-6 text-green-500" />
                                                    </div>
                                                </div>}
                                        </div>

                                        <div className="font-medium text-neutral-800 mb-2">{x.label}{x.required && <span className="text-red-500">*</span>}</div>
                                        <div className="flex">
                                            <div onClick={() => noteTabIds.includes(x.id) ? setNoteTabIds(noteTabIds.filter(y => y != x.id)) : {}} className={`cursor-pointer text-sm p-1 flex-1 text-center text-neutral-600 ${!noteTabIds.includes(x.id) ? 'border-b-2 border-blue-500 font-semibold' : 'border-b border-neutral-200 '}`}>Answer</div>
                                            <div onClick={() => !noteTabIds.includes(x.id) ? setNoteTabIds(noteTabIds.concat(x.id)) : {}} className={`cursor-pointer text-sm p-1 flex-1 text-center text-neutral-600 ${noteTabIds.includes(x.id) ? 'border-b-2 border-blue-500 font-semibold' : 'border-b border-neutral-200 '}`}>Supporting Information</div>
                                        </div>

                                        {!noteTabIds.includes(x.id) && <div className="mt-2">
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 0 || x.type === 0) && <div>
                                                <TextField value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 1 || x.type === 1) && <div>
                                                <TextAreaField value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 2 || x.type === 2) && <div>
                                                <NumberField value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 3 || x.type === 3) && <div>
                                                <CurrencyField value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 4 || x.type === 4) && <div>
                                                <PercentageField value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 5 || x.type === 5) && <div>
                                                <DateField value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 6 || x.type === 6) && <div>
                                                <SelectField options={x.options} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 7 || x.type === 7) && <div>
                                                <ListBoxField options={x.options} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 8 || x.type === 8) && <div>

                                                <div className="flex justify-around space-x-4">
                                                    <div onClick={() => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: true } : { [x.id]: true } })} className={`cursor-pointer p-3 rounded-full w-full bg-neutral-200  text-center font-semibold select-none ${(values.values && values.values[x.id] === true) ? 'bg-blue-300 border  shadow text-neutral-50' : 'text-neutral-600'}`}>Yes</div>
                                                    <div onClick={() => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: false } : { [x.id]: false } })} className={`cursor-pointer p-3 rounded-full w-full bg-neutral-200  text-center font-semibold select-none ${(values.values && values.values[x.id] === false) ? 'bg-blue-300 border  shadow text-neutral-50' : 'text-neutral-600'}`}>No</div>
                                                </div>

                                            </div>}
                                            {(allMetrics.find(y => y._id == x.metric)?.type === 9 || x.type === 9) && <div>
                                                <FileField endpoint="document" value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                            </div>}
                                            <div className="text-neutral-500 text-sm mt-2">{x.helpText}</div>

                                        </div>}

                                        {noteTabIds.includes(x.id) && <div className="mt-2">

                                            <TextAreaField label="Supporting Information" value={values.supportingInformation ? values.supportingInformation[x.id]?.notes : null} onChange={(val) => setValues({ ...values, supportingInformation: values.supportingInformation ? { ...values.supportingInformation, [x.id]: values.supportingInformation[x.id] ? { ...values.supportingInformation[x.id], notes: val } : { notes: val } } : { [x.id]: { notes: val } } })}></TextAreaField>
                                            <div className="text-neutral-500 text-sm mt-2">Enter any supporting information or notes about data accuracy/coverage here</div>

                                        </div>}

                                    </div>)}
                                </div>
                            </div>


                            <div className="bg-white border-t p-2 ">
                                <div className="max-w-3xl w-full mx-auto">
                                    {currentSection > 0 && <button
                                        type="button"
                                        onClick={() => setCurrentSection(currentSection - 1)}
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        <ChevronLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        Previous
                                    </button>}
                                    {currentSection < data.sections.length - 1 && <button
                                        type="button"
                                        onClick={() => setCurrentSection(+currentSection + 1)}
                                        className="float-right inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        Next
                                        <ChevronRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                    </button>}
                                </div>

                            </div>
                        </div>

                    </div>}
                </div>
            }
        </FormWrapper>
    </div>
}