import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import logo from './logo-dark.png'
import {
  ArrowDownIcon,
  ArrowUpOnSquareIcon,
  Bars3CenterLeftIcon,
  BoltIcon,
  ChatBubbleLeftIcon,
  ClockIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  GlobeEuropeAfricaIcon,
  HomeIcon,
  LightBulbIcon,
  LinkIcon,
  MapIcon,
  ScaleIcon,
  UserGroupIcon,
  XMarkIcon,
  DocumentIcon,
  TableCellsIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { api } from './api.service'

const navigation = [
  { name: 'Home', href: '/0', icon: HomeIcon, current: true },
  { name: 'Edit Data', href: '/0/metrics', icon: TableCellsIcon, current: true },
]
const secondaryNavigation = [
  { name: 'Logout', href: '/logout', icon: ArrowDownIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PcLayout() {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [dashboards, setDashboards] = useState([])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/dashboards`).then(x => { setDashboards(x); }); }, [])

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-blue-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-700 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="w-full h-24 object-scale-down"
                      src={logo}
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-blue-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <Link
                          onClick={() => setSidebarOpen(false)}
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            location.pathname.endsWith(item.href)
                              ? 'bg-blue-800 text-white'
                              : 'text-blue-100 hover:text-white hover:bg-blue-600',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                          {item.name}
                        </Link>
                      ))}
                      {dashboards.map(x => <Link
                        onClick={() => setSidebarOpen(false)}
                        to={'/0/dashboard/' + x._id}
                        className={classNames(
                          location.pathname.endsWith('/0/dashboard/' + x._id) ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600',
                          'group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md'
                        )}
                      >
                        <ChartBarIcon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                        {x.name}
                      </Link>)}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-blue-100 hover:bg-blue-600 hover:text-white"
                          >
                            <item.icon className="mr-4 h-6 w-6 text-blue-200" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto bg-blue-700 pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-12 mx-auto object-cover"
                src={logo}
              />
            </div>
            <nav className="mt-5 flex flex-1 flex-col divide-y divide-blue-800 overflow-y-auto" aria-label="Sidebar">
              <div className="space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      location.pathname.endsWith(item.href) ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600',
                      'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                    {item.name}
                  </Link>
                ))}
                {dashboards.map(x => <Link
                  to={'/0/dashboard/' + x._id}
                  className={classNames(
                    location.pathname.endsWith('/0/dashboard/' + x._id) ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600',
                    'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                  )}
                >
                  <ChartBarIcon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                  {x.name}
                </Link>)}

              </div>
              <div className="mt-6 pt-6">
                <div className="space-y-1 px-2">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-blue-100 hover:bg-blue-600 hover:text-white"
                    >
                      <item.icon className="mr-4 h-6 w-6 text-blue-200" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-1 flex-col lg:pl-64 bg-neutral-50 min-h-screen">
          <div className="bg-blue-700 p-2 lg:hidden">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 lg:px-0 lg:pt-0 px-3 pt-3">
            <Outlet></Outlet>
          </main>
        </div>
      </div>
    </>
  )
}
