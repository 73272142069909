import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import CurrencyField from "./fields/currency-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";
import NumberField from "./fields/number-field";
import Button from "../components/button";

export default function StaticValueForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader headline="Manage Conversion Factor"></PageHeader>
        <FormWrapper url="admin/static-value" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) => <div className="">
                <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                <TextField label="Source" value={values.source} onChange={(val) => setValues({ ...values, source: val })}></TextField>
                {values.values?.map((x, i) => <div className="grid grid-cols-2 gap-2">
                    <NumberField label="Value" value={x.value} onChange={(val) => setValues({ ...values, values: values.values.map((z, j) => j === i ? { ...z, value: val } : z) })}></NumberField>
                    <DateField label="Date" value={x.date} onChange={(val) => setValues({ ...values, values: values.values.map((z, j) => j === i ? { ...z, date: val } : z) })}></DateField>
                </div>)}
                <Button className="mt-5" text="Add Value" onClick={() => setValues({ ...values, values: values.values ? values.values.concat([{}]) : [{}] })}></Button>
            </div>}
        </FormWrapper>
    </div>
}