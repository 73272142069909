import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from './logo.png'

export default function RequestReset() {

  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/request-token`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(res => { return res.json() }).then(x => {
      submitted(true)
    })
  }

  return (
    <>
      {submitted && <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-24 text-center text-3xl font-bold tracking-tight text-blue-500">Reset Password</h2>
          <p className="mt-2 text-center text-sm text-blue-400">
            Or{' '}
            <a href="/login" className="font-medium text-blue-300 hover:text-blue-400">
              login
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-blue-700 text-center">
                  If that account exists we have sent you an email with instructions to reset your password
                </label>
              </div>


            </div>

          </div>
        </div>
      </div>}
      {!submitted && <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-24 text-center text-3xl font-bold tracking-tight text-blue-500">Reset Password</h2>
          <p className="mt-2 text-center text-sm text-blue-400">
            Or{' '}
            <a href="/login" className="font-medium text-blue-300 hover:text-blue-400">
              login
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-blue-700 text-center">
                  {error}
                </label>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-blue-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-blue-300 px-3 py-2 placeholder-blue-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>


              <div>
                <button
                  onClick={() => submit()}
                  type="button"
                  className="flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </>
  )
}
