import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { ArrowTrendingUpIcon, ChartBarIcon, Cog6ToothIcon, ComputerDesktopIcon, SunIcon, TableCellsIcon } from '@heroicons/react/20/solid'
import { Card, Metric, Text, LineChart, Title, BarChart } from "@tremor/react";
import { Fragment } from 'react'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid'
import { formatMetric, format_currency } from './formatter'
import { DateStringToDate, GetDateString } from './date.service';
import { Dialog, Transition } from '@headlessui/react'
import { LineChart as RCLineChart, Line as RCLine } from 'recharts';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { GetComputationalMetricValue, GetFlag } from './computations'
import FlagPopup from './components/flag-popup'
import ListBoxField from './forms/fields/listbox-field'
import { DateRangePicker } from "@tremor/react";
import { ExportToCsv } from 'export-to-csv'

const dataFormatter = (value, type, unit) => {
  if (type === 3) {
    return format_currency(value)
  }
  if (type === 4) {
    return value + "%"
  }
  return value
}

export default function AdminDataExport() {
  const navigate = useNavigate();

  const [values, setValues] = useState({})

  const [queryResult, setQueryResults] = useState(null)
  const [allMetrics, setAllMetrics] = useState(null)
  const [metrics, setMetrics] = useState(null)
  const [staticValues, setStaticValues] = useState(null)
  const [_metricValues, setMetricValues] = useState(null)
  const [portfolioCompanies, setPortfolioCompanies] = useState(null)

  const [metric, setMetric] = useState(false)
  const [open2, setOpen2] = useState(false)
  // const [dateRange, setDateRange] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => { setAllMetrics(x); setMetrics(x) }) }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric-values`).then(x => { { setMetricValues(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/static-value`).then(x => { { setStaticValues(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => setPortfolioCompanies(x)) }, [])

  const [pcs, setPcs] = useState([]);
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => { { setPcs(x) }; }) }, [])

  if (!allMetrics || !_metricValues || !portfolioCompanies || !staticValues) return <></>

  let metricValues = _metricValues.slice().sort((a, b) => DateStringToDate(a.date, allMetrics.find(x => x._id == a.metric)?.interval) > DateStringToDate(b.date, allMetrics.find(x => x._id == b.metric)?.interval) ? 1 : -1)

  const RunQuery = () => {

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: ['PC', ...values.metrics.map(x => metrics.find(y => y._id == x)?.name)]
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(loadStaticChartData(metrics.filter(x => values.metrics.includes(x._id)), metricValues, metrics, metricValues, false));

  }

  const loadChartData = (metrics, metricValues, allMetrics, allValues, isAggregate) => {
    let outputs = [];
    let dates = [];

    if (metrics.find(x => x.computational)) dates = getDates(metrics, allValues, allMetrics)
    else dates = getDates(metrics, metricValues, allMetrics)


    for (let date of dates) {
      let output = { date: moment(date).format("YYYY/MM/DD") };

      if (isAggregate) {

        for (let m of metrics) {

          output[m.name] = 0;
          for (let pc of pcs) {

            if (m.computational) output[m.name] += Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues) * 100) / 100
            else
              output[m.name] += +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) >= date)
                .map(x => x.value)
                .slice().reverse().pop() ?? 0)
          }
        }
      }
      else {
        for (let pc of pcs) {

          for (let m of metrics) {
            if (m.computational) output[pc?.name] = Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues) * 100) / 100
            else
              output[m.name] = +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) >= date)
                .map(x => x.value)
                .slice().reverse().pop() ?? 0)
          }
        }
      }
      outputs.push(output)
    }

    return outputs;
  }

  const loadStaticChartData = (metrics, metricValues, allMetrics, allValues, isAggregate) => {
    let date = new Date()
    let outputs = [];

    if (isAggregate) {

      for (let m of metrics) {
        let output = { [m.name]: 0 };
        output.name = m.name
        for (let pc of pcs) {

          if (m.computational) output[m.name] += Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues) * 100) / 100
          else
            output[m.name] += +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) <= date)
              .map(x => x.value)
              .slice().reverse().pop() ?? 0)
        }
        outputs.push(output)
      }
    }
    else {
      for (let pc of pcs) {
        let output = {};
        output.name = pc.name;
        for (let m of metrics) {
          if (m.computational) output[m.name] = Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues) * 100) / 100
          else {
            output[m.name] = +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) <= date)
              .map(x => x.value)
              .slice().reverse().pop() ?? 0)
          }

        }
        outputs.push(output)
      }
    }
    console.log(outputs)

    return outputs;
  }

  return (<>

    <PageHeader subtitle={"Export data from your portfolio"} headline="Data Export"></PageHeader>
    <div className="max-w-6xl mx-auto mt-10">
      {metricValues && portfolioCompanies && <div className="flex flex-col transition ease-in">

        <ListBoxField label={'Metrics'} optionListName={'metrics'} value={values.metrics} onChange={(val) => setValues({ ...values, metrics: val })}></ListBoxField>

        <Button onClick={() => RunQuery()} className="w-96 my-5" text="Export"></Button>

      </div>}

    </div>
  </>
  )
}

const getDates = (metrics, _metricValues, allMetrics) => {
  //Ignore 'one off' metrics and blank metrics
  let metricValues = _metricValues.slice().filter(x => x && allMetrics.find(y => y._id == x.metric)?.interval < 5 && x.value !== "")
  let lowestInterval = (getLowestInterval(metrics));
  if (!metricValues.length) return []
  let endDate = DateStringToDate(metricValues[0]?.date, allMetrics.find(x => x._id == metricValues[0].metric)?.interval);

  let startDate = DateStringToDate(metricValues[metricValues.length - 1]?.date, allMetrics.find(x => x._id == metricValues[metricValues.length - 1].metric)?.interval);

  let dates = [];
  let date = startDate;
  while (date <= endDate) {
    dates.push(date);
    let timeRange = ''
    if (lowestInterval == 0) timeRange = 'week';
    if (lowestInterval == 1) timeRange = 'month';
    if (lowestInterval == 2) timeRange = 'quarter';
    if (lowestInterval == 3) timeRange = 'biannual';
    if (lowestInterval == 4) timeRange = 'year';
    if (timeRange == 'biannual') {
      date = moment(date).add(1, 'quarter').startOf('quarter').toDate()
    }
    else {
      date = moment(date).add(1, timeRange).startOf(timeRange).toDate()
    }
  }
  return dates;
}

const getLowestInterval = (metrics) => {
  let lowest = metrics[0]?.interval;
  for (let m of metrics) {
    if (m.interval < lowest) lowest = m.interval;
  }
  return lowest;
}
