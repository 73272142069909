import { Routes, Route, useParams } from "react-router-dom";
import AdminAggregateScorecard from "./admin-aggregate-scorecard";
import AdminApproval from "./admin-approval";
import AdminApprovals from "./admin-approvals";
import AdminDashboard from "./admin-dashboard";
import AdminDashboards from "./admin-dashbords";
import AdminDataDashboard from "./admin-data-dashboard";
import AdminDataExport from "./admin-data-export";
import AdminDataManage from "./admin-data-manage";
import DataQuery from "./admin-data-query";
import AdminEVs from "./admin-evs";
import AdminForms from "./admin-forms";
import AdminGroups from "./admin-groups";
import AdminInvestments from "./admin-investments";
import AdminInvestor from "./admin-investor";
import AdminInvestors from "./admin-investors";
import AdminLayout from "./admin-layout";
import AdminMetrics from "./admin-metrics";
import AdminMetricsDisplay from "./admin-metrics-display";
import AdminNews from "./admin-news";
import AdminPc from "./admin-pc";
import AdminPCs from "./admin-pcs";
import AdminReport from "./admin-report";
import AdminReports from "./admin-reports";
import AdminScorecards from "./admin-scorecards";
import AdminStaticValues from "./admin-static-values";
import AdminUsers from "./admin-users";
import './App.css';
import AdminSettingsForm from "./forms/admin-settings-form";
import DashboardForm from "./forms/dashboard-form";
import EvForm from "./forms/ev-form";
import FormForm from "./forms/form-form";
import FormResponseForm from "./forms/form-response-form";
import GroupForm from "./forms/group-form";
import InvestmentForm from "./forms/investment-form";
import InvestorForm from "./forms/investor-form";
import MetricForm from "./forms/metric-form";
import NewsForm from "./forms/news-form";
import PcForm from "./forms/pc-form";
import ReportForm from "./forms/report-form";
import ScorecardForm from "./forms/scorecard-form";
import StaticValueForm from "./forms/static-value-form";
import UserForm from "./forms/user-form";
import InvestorDashboard from "./investor-dashboard";
import InvestorLayout from "./investor-layout";
import InvestorPortfolio from "./investor-portfolio";
import InvestorSettings from "./investor-settings";
import Login from "./login";
import Logout from "./logout";
import PcDashboard from "./pc-dashboard";
import PcDataDashboard from "./pc-data-dashboard";
import PcForms from "./pc-forms";
import PcLayout from "./pc-layout";
import PcMetrics from "./pc-metrics";
import PcSettings from "./pc-settings";
import Register from "./register";
import RenderReport from "./render-report";
import RequestReset from "./request-reset";
import ResetPassword from "./reset-password";
import Welcome from "./welcome";

export default function App() {
  const params = useParams();
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/request-reset" element={<RequestReset />} />
      <Route path="/passwordReset" element={<ResetPassword />} />
      <Route path="/welcome" element={<Welcome />} />

      <Route path="/render-report/:reportId/:pcId/:startDate/:endDate" element={<RenderReport />} />

      <Route path='/0' element={<PcLayout />}>
        <Route path="/0" element={<PcDashboard></PcDashboard>} />

        <Route path="/0/forms" element={<PcForms></PcForms>} />
        <Route path="/0/form/:id/:date" element={<FormResponseForm></FormResponseForm>} />

        <Route path="/0/metrics" element={<PcMetrics></PcMetrics>} />
        <Route path="/0/settings" element={<PcSettings></PcSettings>} />

        <Route path="/0/dashboard/:id" element={<PcDataDashboard></PcDataDashboard>} />

      </Route>

      <Route path='/1' element={<InvestorLayout></InvestorLayout>}>
        <Route path="/1" element={<InvestorDashboard></InvestorDashboard>} />
        <Route path="/1/pc/:id" element={<AdminPc></AdminPc>} />

        <Route path="/1/portfolio" element={<InvestorPortfolio></InvestorPortfolio>} />
        <Route path="/1/settings" element={<InvestorSettings></InvestorSettings>} />
      </Route>

      <Route path='/2' element={<AdminLayout></AdminLayout>}>
        <Route path="/2" element={<AdminDashboard></AdminDashboard>} />

        <Route path="/2/pc/:id" element={<AdminPc admin={true}></AdminPc>} />
        <Route path="/2/pcs" element={<AdminPCs></AdminPCs>} />
        <Route path="/2/manage-pc/:id" element={<PcForm></PcForm>} />

        <Route path="/2/settings" element={<AdminSettingsForm></AdminSettingsForm>} />

        <Route path="/2/investors" element={<AdminInvestors></AdminInvestors>} />
        <Route path="/2/manage-investor/:id" element={<InvestorForm></InvestorForm>} />
        <Route path="/2/investor/:id" element={<AdminInvestor></AdminInvestor>} />

        <Route path="/2/manage-investment/:id" element={<InvestmentForm></InvestmentForm>} />
        <Route path="/2/investments" element={<AdminInvestments></AdminInvestments>} />

        <Route path="/2/manage-static-value/:id" element={<StaticValueForm></StaticValueForm>} />
        <Route path="/2/static-value" element={<AdminStaticValues></AdminStaticValues>} />

        <Route path="/2/manage-ev/:id" element={<EvForm></EvForm>} />
        <Route path="/2/evs" element={<AdminEVs></AdminEVs>} />

        <Route path="/2/manage-scorecard/:id" element={<ScorecardForm></ScorecardForm>} />
        <Route path="/2/scorecards" element={<AdminScorecards></AdminScorecards>} />

        <Route path="/2/manage-form/:id" element={<FormForm></FormForm>} />
        <Route path="/2/forms" element={<AdminForms></AdminForms>} />
        <Route path="/2/aggregate-scorecard/:category" element={<AdminAggregateScorecard></AdminAggregateScorecard>} />

        <Route path="/2/manage-data-point/:id" element={<MetricForm category="general"></MetricForm>} />
        <Route path="/2/manage-metric/:id" element={<MetricForm category="metrics"></MetricForm>} />
        <Route path="/2/manage-esg/:id" element={<MetricForm category="esg"></MetricForm>} />
        <Route path="/2/metric-setup" element={<AdminMetrics></AdminMetrics>} />
        <Route path="/2/metrics" element={<AdminMetricsDisplay></AdminMetricsDisplay>} />

        <Route path="/2/manage-user/:id" element={<UserForm></UserForm>} />
        <Route path="/2/users" element={<AdminUsers></AdminUsers>} />

        <Route path="/2/manage-news/:id" element={<NewsForm></NewsForm>} />
        <Route path="/2/news" element={<AdminNews></AdminNews>} />
        <Route path="/2/data-query" element={<DataQuery></DataQuery>} />

        <Route path="/2/manage-group/:id" element={<GroupForm></GroupForm>} />
        <Route path="/2/groups" element={<AdminGroups></AdminGroups>} />

        <Route path="/2/dashboard/:id" element={<AdminDataDashboard></AdminDataDashboard>} />
        <Route path="/2/manage-dashboard/:id" element={<DashboardForm></DashboardForm>} />
        <Route path="/2/dashboards" element={<AdminDashboards></AdminDashboards>} />

        <Route path="/2/preview-report/:id" element={<AdminReport></AdminReport>} />
        <Route path="/2/manage-report/:id" element={<ReportForm></ReportForm>} />
        <Route path="/2/reports" element={<AdminReports></AdminReports>} />

        <Route path="/2/manage-metrics-data" element={<AdminDataManage></AdminDataManage>} />
        <Route path="/2/export-data" element={<AdminDataExport></AdminDataExport>} />

        <Route path="/2/approvals" element={<AdminApprovals></AdminApprovals>} />
        <Route path="/2/approval/:id" element={<AdminApproval></AdminApproval>} />
      </Route>
    </Routes>
  )
}
