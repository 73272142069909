import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import logo from './logo-dark.png'
import {
  ArrowDownIcon,
  ArrowUpOnSquareIcon,
  Bars3CenterLeftIcon,
  BoltIcon,
  ChatBubbleLeftIcon,
  ClockIcon,
  Cog6ToothIcon,
  CurrencyPoundIcon,
  FolderIcon,
  GlobeAltIcon,
  GlobeEuropeAfricaIcon,
  HomeIcon,
  LightBulbIcon,
  LinkIcon,
  MapIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  TableCellsIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
  DocumentIcon, ChartBarIcon, PresentationChartLineIcon, ClipboardDocumentCheckIcon, SunIcon, CheckIcon, EyeIcon, PencilIcon, BuildingOffice2Icon, ArrowDownTrayIcon, ChartPieIcon, ChartBarSquareIcon, PowerIcon
} from '@heroicons/react/24/outline'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { api } from './api.service'
import { Bars3BottomLeftIcon, VariableIcon } from '@heroicons/react/20/solid'

const navigation = [
  { name: 'Home', href: '/2', icon: HomeIcon },
  { name: 'Portfolio Companies', href: '/2/pcs', icon: BuildingOffice2Icon },
  { name: 'Browse Data', href: '/2/metrics', icon: EyeIcon },
  { name: 'Data Query', href: '/2/data-query', icon: QuestionMarkCircleIcon },
  { name: 'Approvals', href: '/2/approvals', icon: CheckIcon },
  { isDashboards: true },
  { isScorecards: true },
  { name: 'Data Framework', href: '/2/metric-setup', icon: TableCellsIcon, current: true, category: 'Setup' },
  { name: 'Form Builder', href: '/2/forms', icon: DocumentIcon },
  { name: 'Dashboards', href: '/2/dashboards', icon: ChartBarIcon },
  { name: 'Reports', href: '/2/reports', icon: ChartPieIcon },
  { name: 'Scorecards', href: '/2/scorecards', icon: CheckIcon },
  { name: 'Conversion Factors', href: '/2/static-value', icon: VariableIcon },
  { name: 'Groups', href: '/2/groups', icon: UserGroupIcon },
  // { name: 'Investors', href: '/2/investors', icon: UserGroupIcon, current: true, category: 'Investors' },
  // { name: 'Investments', href: '/2/investments', icon: CurrencyPoundIcon},
  // { name: 'Valuations', href: '/2/evs', icon: PresentationChartLineIcon},
  // { name: 'News', href: '/2/news', icon: GlobeAltIcon, current: true},
  { name: 'System Users', href: '/2/users', icon: UserCircleIcon, current: true, category: 'Admin' },
  { name: 'Edit Data', href: '/2/manage-metrics-data', icon: PencilIcon },
  { name: 'Export Data', href: '/2/export-data', icon: ArrowDownTrayIcon },
  { name: 'Settings', href: '/2/settings', icon: Cog6ToothIcon },
]
const secondaryNavigation = [
  { name: 'Logout', href: '/logout', icon: ArrowDownIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminLayout() {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [dashboards, setDashboards] = useState([])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/dashboard`).then(x => { setDashboards(x.filter(x => x.isAggregate)); }); }, [])
  const [scorecards, setScorecards] = useState([])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => { setScorecards(x.map(x => x.category).filter((value, index, array) => array.indexOf(value) === index)) }); }, [])

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-blue-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-700 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="w-full h-12 object-cover"
                      src={logo}
                    />
                  </div>
                  <nav
                    className="mt-5 h-full flex-shrink-0 divide-y divide-blue-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (<>
                        {item.isDashboards && <div></div>}
                        {item.isScorecards && <div></div>}
                        {!item.isScorecards && !item.isDashboards && <>
                          {item.category && <div className="text-lg">{item.category}</div>}
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              location.pathname.endsWith(item.href)
                                ? 'bg-blue-800 text-white'
                                : 'text-blue-100 hover:text-white hover:bg-blue-600',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            )}
                          >
                            <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                            {item.name}
                          </Link>

                        </>}
                      </>


                      ))}
                    </div>
                    <div className="mt-6 pt-6">
                      <div className="space-y-1 px-2">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-blue-100 hover:bg-blue-600 hover:text-white"
                          >
                            <item.icon className="mr-4 h-6 w-6 text-blue-200" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto bg-blue-700 pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-12 mx-auto object-cover"
                src={logo}
              />
            </div>

            <nav className="mt-5 flex flex-1 flex-col divide-y divide-blue-800 overflow-y-auto" aria-label="Sidebar">
              <div className="space-y-1 px-2">
                {navigation.map((item) => (<>
                  {!item.isDashboards && !item.isScorecards && <>
                    {item.category && <div className="text-xs text-blue-500 font-semibold ml-3 pt-5">{item.category}</div>}
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        location.pathname.endsWith(item.href) ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                      {item.name}
                    </Link>
                  </>}
                  {item.isDashboards && <>

                    <div className="text-xs text-blue-500 font-semibold ml-3 pt-5">Dashboards</div>


                    {dashboards.map(x => <Link
                      to={'/2/dashboard/' + x._id}
                      className={classNames(
                        location.pathname.endsWith('/2/dashboard/' + x._id) ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                      )}
                    >
                      <ChartBarSquareIcon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                      {x.name}
                    </Link>)}
                  </>}
                  {item.isScorecards && <>

                    <div className="text-xs text-blue-500 font-semibold ml-3 pt-5">Scorecards</div>

                    {scorecards.map(x => <Link
                      to={'/2/aggregate-scorecard/' + x}
                      className={classNames(
                        location.pathname.endsWith('/2/aggregate-scorecard/' + x) ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                      )}
                    >
                      <Bars3BottomLeftIcon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-200" aria-hidden="true" />
                      {x}
                    </Link>)}
                  </>}
                </>


                ))}
              </div>
              <div className="mt-6 pt-6">
                <div className="space-y-1 px-2">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-blue-100 hover:bg-blue-600 hover:text-white"
                    >
                      <item.icon className="mr-4 h-6 w-6 text-blue-200" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-1 flex-col lg:pl-64 bg-neutral-50 min-h-screen">

          <main className="flex-1">
            <Outlet></Outlet>
          </main>
        </div>
      </div>
    </>
  )
}
