import { DateStringToDate } from './date.service';
import { formatMetric } from './formatter';

const Parser = require('expr-eval').Parser;

export const GetComputationalMetricValue = (metric, metricValues, date, metrics, staticValues) => {
    metricValues = metricValues.slice().filter(x => x.value !== "")
    const parser = new Parser();
    let equationParts = metric.equation?.split(/[{}]+/);
    let eq = ""
    if (!equationParts?.length) return NaN
    for (let part of equationParts) {
        if (part.startsWith("metric_")) {
            let metricId = part.substring(7);
            let eqMetric = metrics.find(x => x._id == metricId)
            if (!eqMetric) {
                eq += 0;
                continue
            }
            if (eqMetric.computational) {
                let val = +GetComputationalMetricValue(eqMetric, metricValues, date, metrics, staticValues);
                if (!isNaN(val) && isFinite(val))
                    eq += val
                else
                    eq += 0
            }
            else {
                let value = metricValues
                    .filter(x => !date || DateStringToDate(x.date, eqMetric.interval) <= date)
                    .find(x => x.metric == metricId)?.value;

                eq += '' + (value ?? 0);
            }
        }
        else if (part.startsWith("sv_")) {
            let static_value = staticValues?.find(x => x._id == part.substring(3))
            let value = static_value?.values?.filter(x => !date || new Date(x.date) <= date).sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1).pop()
            if (value?.value) eq += '' + (value.value ?? 0)
        }
        else {
            eq += part;
        }
    }
    try {
        let expr = parser.parse(eq);
        let val = expr.evaluate();
        if (!isNaN(val) && isFinite(val))
            return val
        return 0
    } catch (error) {
        // console.log("Failed to calculate " + metric.name + " : eq" + eq, equationParts)
        return 0;
    }
}

export const CalculateScorecard = (scorecard, _metricValues, date, metrics, staticValues) => {
    _metricValues = _metricValues.slice().filter(x => x.value !== "")
    let scores = []
    let total = 0;
    let totalMax = 0;
    for (let m of scorecard.metrics) {
        try {
            let metric = metrics.find(x => x._id == m.metric);
            if (!metric) continue
            let score = { metric: metric.name, maxScore: m.maxScore, score: 0, rules: [] };
            totalMax += +m.maxScore;
            let metricValues = _metricValues.slice().filter(x => (DateStringToDate(x.date, metric.interval) <= date || metric.interval == 5)).slice().reverse()
            let thisMetricValues = _metricValues.slice().filter(x => x.metric == metric._id && (DateStringToDate(x.date, metric.interval) <= date || metric.interval == 5)).slice().reverse()

            if (!metric.computational) {

                if (thisMetricValues[0] === undefined || thisMetricValues[0] === null) {
                    scores.push(score);
                    continue;
                }
                score.value = formatMetric(thisMetricValues[0].value, metric.type, metric, metricValues);
            }

            if (metric.computational) {
                score.value = Math.round(+GetComputationalMetricValue(metric, metricValues.slice().reverse(), date, metrics, staticValues) * 100) / 100

                for (let rule of m.rules) {
                    if (eval(score.value + ' ' + rule.value)) {
                        score.score += +rule.score;
                        total += +rule.score;
                        score.rules.push({ value: rule.value, score: rule.score, pass: true })
                    }
                    else {
                        score.rules.push({ value: rule.value, score: rule.score, pass: false })
                    }
                }
            }
            //Answered or not
            else if ([0, 1, 9].includes(metric.type)) {
                for (let rule of m.rules) {
                    if (rule.value === true) {
                        if (thisMetricValues[0].value !== null && thisMetricValues[0].value !== undefined) {
                            score.score += +rule.score;
                            total += +rule.score;
                            score.rules.push({ value: "Answered", score: rule.score, pass: true })
                        }
                        else {
                            score.rules.push({ value: "Answered", score: rule.score, pass: false })
                        }
                    }
                    else if (rule.value === false) {
                        if (thisMetricValues[0].value === null || thisMetricValues[0].value === undefined) {
                            score.score += +rule.score;
                            total += +rule.score;
                            score.rules.push({ value: "Not Answered", score: rule.score, pass: true })
                        }
                        else {
                            score.rules.push({ value: "Not Answered", score: rule.score, pass: false })
                        }
                    }
                }
            }
            //Numeric
            else if ([2, 3, 4].includes(metric.type)) {
                for (let rule of m.rules) {
                    if (eval(thisMetricValues[0].value + ' ' + rule.value)) {
                        score.score += +rule.score;
                        total += +rule.score;
                        score.rules.push({ value: rule.value, score: rule.score, pass: true })
                    }
                    else {
                        score.rules.push({ value: rule.value, score: rule.score, pass: false })
                    }
                }
            }
            //Date
            else if ([5].includes(metric.type)) {
                for (let rule of m.rules) {
                    if (eval(thisMetricValues[0].value + ' ' + rule.value)) {
                        score.score += +rule.score;
                        total += +rule.score;
                        score.rules.push({ value: rule.value, score: rule.score, pass: true })
                    }
                    else {
                        score.rules.push({ value: rule.value, score: rule.score, pass: false })
                    }
                }
            }
            //Select
            else if ([6].includes(metric.type)) {
                for (let rule of m.rules) {
                    if (thisMetricValues[0].value === rule.value) {
                        score.score += +rule.score;
                        total += +rule.score;
                        score.rules.push({ value: rule.value, score: rule.score, pass: true })
                    }
                    else {
                        score.rules.push({ value: rule.value, score: rule.score, pass: false })
                    }
                }
            }
            else if ([7].includes(metric.type)) {
                for (let rule of m.rules) {
                    if (thisMetricValues[0].value.includes(rule.value)) {
                        score.score += +rule.score;
                        total += +rule.score;
                        score.rules.push({ value: rule.value, score: rule.score, pass: true })

                    }
                    else {
                        score.rules.push({ value: rule.value, score: rule.score, pass: false })
                    }
                }
            }
            //Yes No
            else if ([8].includes(metric.type)) {
                for (let rule of m.rules) {
                    
                    if (rule.value === true) {
                        if (thisMetricValues[0].value === "true") {
                            score.score += +rule.score;
                            total += +rule.score;
                            score.rules.push({ value: "Yes", score: rule.score, pass: true })
                        }
                        else {
                            score.rules.push({ value: "Yes", score: rule.score, pass: false })
                        }
                    }
                    else if (rule.value === false) {
                        if (thisMetricValues[0].value === "false") {
                            score.score += +rule.score;
                            total += +rule.score;
                            score.rules.push({ value: "No", score: rule.score, pass: true })
                        }
                        else {
                            score.rules.push({ value: "No", score: rule.score, pass: false })
                        }
                    }
                }
            }

            scores.push(score);
        } catch (error) {
            console.log(error)
        }

    }

    return { scores, total: total, totalMax: totalMax, overall: Math.round(total / totalMax * 100) };
}

export const GetFlag = (metric, _metricValues, date, metrics, staticValues) => {
    if (!metric.flagRules) return null
    _metricValues = _metricValues.slice().filter(x => x.value !== "")
    let flags = []
    let metricValues = _metricValues.slice().filter(x => (DateStringToDate(x.date, metric.interval) <= date || metric.interval == 5)).slice().reverse()
    let thisMetricValues = _metricValues.slice().filter(x => x.metric == metric._id && (DateStringToDate(x.date, metric.interval) <= date || metric.interval == 5)).slice().reverse()

    if (metric.computational) {

        let val = Math.round(+GetComputationalMetricValue(metric, metricValues, date, metrics, staticValues) * 100) / 100
        for (let rule of metric.flagRules) {
            if (eval(val + ' ' + rule.value)) {
                flags.push({ value: rule.value })
            }
        }
    }
    //Answered or not
    else if ([0, 1, 9].includes(metric.type)) {
        for (let rule of metric.rules) {
            if (rule.value === true) {
                if (thisMetricValues[0].value !== null && thisMetricValues[0].value !== undefined) {
                    flags.push({ value: rule.value })
                }
            }
            else if (rule.value === false) {
                if (thisMetricValues[0].value === null || thisMetricValues[0].value === undefined) {
                    flags.push({ value: rule.value })
                }
            }
        }
    }
    //Numeric
    else if ([2, 3, 4].includes(metric.type)) {
        for (let rule of metric.rules) {
            if (eval(thisMetricValues[0].value + ' ' + rule.value)) {
                flags.push({ value: rule.value })
            }
        }
    }
    //Date
    else if ([5].includes(metric.type)) {
        for (let rule of metric.rules) {
            if (eval(thisMetricValues[0].value + ' ' + rule.value)) {
                flags.push({ value: rule.value })
            }
        }
    }
    //Select
    else if ([6].includes(metric.type)) {
        for (let rule of metric.rules) {
            if (thisMetricValues[0].value === rule.value) {
                flags.push({ value: rule.value })
            }
        }
    }
    else if ([7].includes(metric.type)) {
        for (let rule of metric.rules) {
            if (thisMetricValues[0].value.includes(rule.value)) {
                flags.push({ value: rule.value })
            }
        }
    }
    //Yes No
    else if ([8].includes(metric.type)) {
        for (let rule of metric.rules) {
            if (rule.value === true) {
                if (thisMetricValues[0].value === true) {
                    flags.push({ value: rule.value })
                }
            }
            else if (rule.value === false) {
                if (thisMetricValues[0].value === false) {
                    flags.push({ value: rule.value })
                }
            }
        }
    }

    return flags;
}