import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid'
import moment from 'moment'

export default function AdminApprovals() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/approvals`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader subtitle={"Approve responses to forms"} headline={"Approvals"}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        <div>
          <Table
            data={data.map(x => {
              return {
                ...x, form: x.form?.name + ' - ' + moment(x.date).format('YYYY/MM/DD'), pc: x.portfolioCompany?.name, date: moment(x.submittedDate).format('YYYY/MM/DD'), status: <span className={`select-none uppercase rounded-full p-1 px-2 text-xs font-semibold ${x.approvalStatus == 'approved' ? 'text-green-600 bg-green-200' : ''} ${x.approvalStatus == 'rejected' ? 'text-red-600 bg-red-200' : ''} ${x.approvalStatus == 'returned' ? 'text-neutral-600 bg-neutral-200' : ''}`}>{x.approvalStatus}</span>,
                view: <span className="cursor-pointer underline" onClick={() => navigate(`/2/approval/${x._id}`)}><EyeIcon className="w-6 text-neutral-400 hover:text-neutral-600"></EyeIcon></span>,
              }
            })} columns={[
              {
                Header: 'Form',
                accessor: 'form',
                width: 300
              },
              {
                Header: 'PC',
                accessor: 'pc',
                width: 150
              },
              {
                Header: 'Status',
                accessor: 'status',
                width: 100
              },
              {
                Header: 'Submitted',
                accessor: 'date',
                width: 100
              },
              {
                Header: 'View',
                accessor: 'view',
                width: 20
              }
            ]}></Table>
        </div>
      </div>
    </div>
  </>
  )
}