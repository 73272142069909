import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { ArrowsPointingOutIcon, ArrowTrendingUpIcon, ChartBarIcon, Cog6ToothIcon, ComputerDesktopIcon, SunIcon, TableCellsIcon } from '@heroicons/react/20/solid'
import { Card, Metric, Text, LineChart, Title } from "@tremor/react";
import { Fragment } from 'react'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid'
import { formatMetric } from './formatter'
import { DateStringToDate, GetDateString } from './date.service';
import { Dialog, Transition } from '@headlessui/react'
import { LineChart as RCLineChart, Line as RCLine } from 'recharts';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { GetComputationalMetricValue, GetFlag } from './computations'
import FlagPopup from './components/flag-popup'

export default function AdminMetricsDisplay({ _pc }) {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [allMetrics, setAllMetrics] = useState(null)
  const [staticValues, setStaticValues] = useState(null)
  const [_metricValues, setMetricVales] = useState(null)
  const [portfolioCompanies, setPortfolioCompanies] = useState(null)

  const [metric, setMetric] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [tab, setTab] = useState(0)

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => setAllMetrics(x)) }, [])

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/data-metrics/${category}`).then(x => setData(x)) }, [tab])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/static-value`).then(x => setStaticValues(x)) }, [tab])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric-values`).then(x => setMetricVales(x)) }, [tab])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => setPortfolioCompanies(x)) }, [])

  let category = tab == 2 ? 'esg' : tab == 1 ? 'metrics' : 'general'
  if (!allMetrics || !data || !_metricValues || !portfolioCompanies || !staticValues) return <></>

  let metricValues = _metricValues.slice().sort((a, b) => DateStringToDate(a.date, allMetrics.find(x => x._id == a.metric)?.interval) > DateStringToDate(b.date, allMetrics.find(x => x._id == b.metric)?.interval) ? 1 : -1)

  const getMetricListData = () => {
    let outputs = [];
    for (let d of data) {
      let metric = {};
      metric.name = d.name;
      metric.category = d.category;
      let mvals = metricValues.filter(x => x.metric == d._id);

      let pcsout = [];

      for (let pc of portfolioCompanies.filter(x => x.metrics?.includes(d._id))) {
        if (!_pc || pc._id == _pc)
          pcsout.push({ name: _pc ? null : pc.name, ...getMetricData(d, mvals.filter(x => x.portfolioCompany._id == pc._id), setOpen2, setMetric, metricValues.filter(x => x.portfolioCompany._id == pc._id), allMetrics, staticValues) });
      }
      metric.pcs = pcsout;
      outputs.push(metric)
    }
    return outputs;
  }

  return (<>
    <Modal2 open={open2} setOpen={setOpen2} metric={metric}></Modal2>
    {!_pc && <PageHeader subtitle={"View all data from across your Portfolio"} headline={category == "metrics" ? 'Metrics' : category == "esg" ? 'ESG' : 'General Data'} ></PageHeader>}
    {data && metricValues && portfolioCompanies && <div className="flex transition ease-in">
      <div className="w-full pb-20 max-w-6xl mx-auto mb-10">

        <Tabs ti={tab} setTab={setTab}>

        </Tabs>

        <div className="border">
          <MetricList data={getMetricListData()}></MetricList>
        </div>
      </div>
    </div>}
  </>
  )
}

const tabs = [
  { name: 'General', href: '#', icon: ClipboardDocumentCheckIcon, current: true },
  { name: 'Metrics', href: '#', icon: TableCellsIcon, current: false },
  { name: 'ESG', href: '#', icon: SunIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Tabs({ ti, setTab }) {
  return (
    <div>
      <div className="select-none">
        <div className="">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, i) => (
              <a onClick={() => setTab(i)}
                key={tab.name}
                className={classNames(
                  i === ti
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    i === ti ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}


          </nav>
        </div>
      </div>
    </div>
  )
}

const getDates = (metrics, _metricValues, allMetrics) => {
  //Ignore 'one off' metrics and blank metrics
  let metricValues = _metricValues.slice().filter(x => x && allMetrics.find(y => y._id == x.metric)?.interval < 5 && x.value !== "")
  let lowestInterval = (getLowestInterval(metrics));
  if (!metricValues.length) return []
  let endDate = DateStringToDate(metricValues[0]?.date, allMetrics.find(x => x._id == metricValues[0].metric)?.interval);

  let startDate = DateStringToDate(metricValues[metricValues.length - 1]?.date, allMetrics.find(x => x._id == metricValues[metricValues.length - 1].metric)?.interval);

  let dates = [];
  let date = startDate;
  while (date <= endDate) {
    dates.push(date);
    let timeRange = ''
    if (lowestInterval == 0) timeRange = 'week';
    if (lowestInterval == 1) timeRange = 'month';
    if (lowestInterval == 2) timeRange = 'quarter';
    if (lowestInterval == 3) timeRange = 'biannual';
    if (lowestInterval == 4) timeRange = 'year';
    if (timeRange == 'biannual') {
      date = moment(date).add(1, 'quarter').startOf('quarter').toDate()
    }
    else {
      date = moment(date).add(1, timeRange).startOf(timeRange).toDate()
    }
  }
  return dates;
}

const getLowestInterval = (metrics) => {
  let lowest = metrics[0]?.interval;
  for (let m of metrics) {
    if (m.interval < lowest) lowest = m.interval;
  }
  return lowest;
}

const getMetricData = (metric, metricValues, setOpen2, setMetric, allMetricValues, metrics, staticValues) => {
  let output = {};
  output.metric = metric.name;
  output.type = metric.type;
  if (metric.computational) {
    output.value = formatMetric('', 'computational', metric, allMetricValues.slice().reverse(), metrics, staticValues)
    output.flags = GetFlag(metric, allMetricValues, new Date(), metrics, staticValues)

    let datav = [];
    let lastDate;
    for (let date of getDates(metrics, allMetricValues.slice().reverse(), metrics)) {
      let op = { date: moment(date).toDate(), type: -1 };

      op.value = +GetComputationalMetricValue(metric, allMetricValues.slice().reverse(), date, metrics, staticValues)

      datav.push(op)
      lastDate = date;
    }
    output.unit = metric.unit

    output.date = GetDateString(lastDate, getLowestInterval(metrics));
    output.sparkline = <div className="flex">
      <Sparkline data={datav.map(x => x.value)}></Sparkline>
      <div onClick={() => { setOpen2(true); setMetric({ name: metric.name, data: datav }) }} className="rounded-lg my-auto bg-blue-200 text-neutral-500 hover:bg-blue-300 cursor-pointer w-5 h-5 p-1"><ArrowsPointingOutIcon></ArrowsPointingOutIcon></div></div>
  }
  else {
    output.unit = metric.unit
    output.value = formatMetric(metricValues.filter(x => x.metric === metric._id && x.value).slice().reverse()[0]?.value, metric.type)
    output.date = metricValues.filter(x => x.metric === metric._id && x.value).slice().reverse()[0]?.date

    if ((metric.type == 2 || metric.type == 3 || metric.type == 4) && metric.interval != 5) {
      let datav = metricValues.filter(x => x.metric === metric._id && x.value).map(x => +x.value)
      output.sparkline = <div className="flex">
        <Sparkline data={datav}></Sparkline>
        <div onClick={() => { setOpen2(true); setMetric({ name: metric.name, data: metricValues.filter(x => x.metric === metric._id && x.value).map(x => { return { value: +x?.value, date: DateStringToDate(x.date, metric.interval), type: x.type } }), type: metric.type }) }} className="rounded-lg my-auto bg-blue-200 text-neutral-500 hover:bg-blue-300 cursor-pointer w-5 h-5 p-1"><ArrowsPointingOutIcon></ArrowsPointingOutIcon></div></div>
    }
  }
  return output;
}

function MetricList({ data }) {
  return (
    <div>
      <div className="overflow-hidden border-t border-gray-100 bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <table className="w-full text-left">
              <tbody>
                {data.sort((a, b) => a.name < b.name ? -1 : 1).sort((a, b) => a.category < b.category ? -1 : 1).map((metric) => (
                  <Fragment key={metric.name}>
                    <tr className="text-sm leading-6 text-gray-900">
                      <th scope="colgroup" colSpan={4} className="relative isolate py-2 font-semibold">
                        {metric.name} <span className="float-right text-neutral-600 text-right">Category - {metric.category} </span>
                        <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                      </th>
                    </tr>
                    {metric.pcs.sort((a, b) => a.name < b.name ? -1 : 1).map((pc, i) => (
                      <tr key={metric.name + 'v' + i}>
                        {pc.name && <td className="relative py-5 pr-6">
                          <div className="flex gap-x-6">
                            <div className="flex-auto">
                              <div className="flex items-start gap-x-3">
                                <div className="text-sm font-medium leading-6 text-gray-900">{pc.name}</div>

                              </div>
                            </div>
                          </div>
                          <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                          <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                        </td>}
                        <td className="relative py-5 pr-6">
                          <div className="flex gap-x-6">
                            <div className="flex-auto">
                              <div className="flex items-start gap-x-3">
                                <div className="text-sm font-medium leading-6 text-gray-900">{pc.value}</div>

                                {pc.flags?.length > 0 && <FlagPopup text={pc.flags.map(x => <div>{x.value}</div>)}></FlagPopup>}
                                <div className="text-xs text-gray-500">{pc.unit}</div>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="hidden py-5 pr-6 sm:table-cell">
                          <div className="text-sm leading-6 text-gray-900">{pc.sparkline}</div>
                        </td>
                        <td className="relative py-5 pr-6">
                          <div className="text-sm font-medium leading-6 text-gray-900 text-right">{pc.date}</div>
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function Modal2({ open, setOpen, metric }) {
  if (!metric) return <></>
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">

                <Card>
                  <Title>{metric.name}</Title>
                  <LineChart
                    className="mt-6"
                    data={metric.data.map(x => { return { date: x.date.toDateString(), [metric.name]: +x.value } })}
                    index="date"
                    categories={[metric.name]}
                    colors={["blue"]}
                    valueFormatter={(number) => formatMetric(+number, metric.type)}
                  />
                </Card>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Sparkline({ data }) {

  const _data = data.map(x => { return { count: x } });

  return (
    <RCLineChart width={60} height={40} data={_data}>
      <RCLine dot={false} dataKey="count" stroke="#22c55e" strokeWidth={2} />
    </RCLineChart>
  )
}