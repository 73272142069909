import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import SelectField from "./fields/select-field";
import PasswordField from "./fields/password-field";
import { useNavigate, useParams } from "react-router-dom";
import CheckboxField from "./fields/checkbox-field";

export default function AdminSettingsForm(props) {
    const params = useParams();
    const navigate = useNavigate();

    return <div>
        <PageHeader headline="Settings" subtitle={"Manage portal wide settings"}  ></PageHeader>
        <FormWrapper noId={true} url="admin/settings" {...props} callback={() => navigate('/2')}>
            {(values, setValues) =>
                <>
                    <div className="grid grid-cols-2 gap-2">
                        <CheckboxField label="Send Notifications" value={values.settings?.sendNotifications} onChange={(val) => setValues({ ...values, settings: values.settings ? { ...values.settings, sendNotifications: val } : { sendNotifications: val } })}></CheckboxField>
                        <TextField label="Notification Email" value={values.settings?.email} onChange={(val) => setValues({ ...values, settings: values.settings ? { ...values.settings, email: val } : { email: val } })}></TextField>
                       
                       </div>
                </>
            }
        </FormWrapper>
    </div>
}