import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import CurrencyField from "./fields/currency-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";
import CheckboxField from "./fields/checkbox-field";

export default function GroupForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader subtitle={"Groups let you breakdown PC data by groupings. Commonly groups are a fund or sector."} headline="Manage Group"></PageHeader>
        <FormWrapper url="admin/group" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) => <div className="grid grid-cols-2 gap-2">
                <SelectField options={[{text: "Fund", value: "Fund"},{text: "Sector", value: "Sector"},{text: "Cohort", value: "Cohort"},{text: "Other", value: "Other"}]} label="Group Type" value={values.type} onChange={(val) => setValues({ ...values, type: val })}></SelectField>
                <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                <TextField label="Description" value={values.description} onChange={(val) => setValues({ ...values, description: val })}></TextField>
            </div>}
        </FormWrapper>
    </div>
}