import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react'
import { api } from '../../api.service';
import Loading from '../../components/loading';

export default function SelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, onBlur, placeholder, metricInterval }) {

    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
        if (optionListName === "pcs") {
            api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "investors") {
            api(`${process.env.REACT_APP_API_URL}/admin/investor`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "metrics") {
            console.log(metricInterval)
            api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => setSelectOptions(x.sort((a, b) => a.name < b.name ? -1 : 1).filter(x => metricInterval == null || metricInterval == 5 || x.interval == metricInterval).sort((a, b) => a.category < b.category ? -1 : 1).map(x => { return { text: x.name + ` (${x.category})`, value: x._id } })))
        }
        if (optionListName === "scorecards") {
            api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => setSelectOptions(x.sort((a, b) => a.name < b.name ? -1 : 1).map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "dashboards") {
            api(`${process.env.REACT_APP_API_URL}/admin/dashboard`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "display-types") {
            setSelectOptions([{ value: 0, text: "Statistic" }, { value: 1, text: "Line Chart" }, { value: 2, text: "Pie Chart" }, { value: 3, text: "Area Chart" }, { value: 4, text: "Table" }, { value: 5, text: "Title" }, { value: 6, text: "Text" }, { value: 7, text: "Bar Chart" }])
        }
        if (optionListName === "data-types") {
            setSelectOptions([{ value: 0, text: "Short Text" }, { value: 1, text: "Long Text" }, { value: 2, text: "Number" }, { value: 3, text: "Currency" }, { value: 4, text: "Percentage" }, { value: 5, text: "Date" }, { value: 6, text: "Single Selection" }, { value: 7, text: "Multi Selection" }, { value: 8, text: "Yes No" }, { value: 9, text: "Document" }])
        }
        if (optionListName === "intervals") {
            setSelectOptions([{ value: 0, text: "Weekly" }, { value: 1, text: "Monthly" }, { value: 2, text: "Quarterly" }, { value: 3, text: "Bi-Annually" }, { value: 4, text: "Annually" }, { value: 5, text: "One Off" }])
        }
        if (optionListName === "dependencyTypes") {
            setSelectOptions([{ value: 0, text: "Equals" }, { value: 1, text: "Not Equals" }, { value: 1, text: "Contains" }])
        }
        if (optionListName === "dependencyTypesNumeric") {
            setSelectOptions([{ value: 0, text: "Equals" }, { value: 1, text: "Not Equals" }, { value: 2, text: "Greater Than" }, { value: 3, text: "Less Than" }])
        }
        if (optionListName === "yesno") {
            setSelectOptions([{ value: true, text: "Yes" }, { value: false, text: "No" }])
        }
    }, [])
    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))
    if (!selectOptions) return <div className="text-center text-xl font-neutral-800 font-bold">...</div>
    return <div className={className}>
        <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>
            {label}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm ">
            <Combobox value={selectOptions.find(x => x.value === value)} onChange={(e) => { onChange(e); }}>
                <div className="relative mt-1">
                    <div className="relative w-full rounded-md  cursor-default overflow-hidden border border-gray-300 bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">

                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                            onChange={(e) => setQuery(e.target.value)}
                            displayValue={(x) => x?.text}
                            placeholder={placeholder}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filtered.map((x) => (
                                <Combobox.Option
                                    key={x.value}
                                    value={x.value}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-blue-600 text-white' : 'text-gray-900'} ${x.value !== value && active ? 'bg-blue-400 text-white' : 'text-gray-900'}`
                                    }
                                >
                                    {x.text}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>

                    </Transition>
                </div>
            </Combobox>
        </div>
    </div>
}

